const movingSteps = [
  {
    id: 1,
    title: "Consultation & Planning",
    text: "We start by understanding your moving needs and timeline to plan every detail meticulously. Our expert team ensures a personalized and efficient moving strategy.",
    link: "/service-details/1",
  },
  {
    id: 2,
    title: "Packing & Handling",
    text: "Our skilled packers use the best materials and techniques to protect your belongings. Every item, from the most robust to the most delicate, is handled with care.",
    link: "/service-details/2",
  },
  {
    id: 3,
    title: "Delivery & Setup",
    text: "We ensure timely delivery to your new location and can assist with the setup of furniture and appliances to help you settle into your new home or office faster.",
    link: "/service-details/5",
  },
];

export default movingSteps;
