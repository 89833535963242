import React from "react";
import { Link } from "react-router-dom";

const CTA = () => {
  return (
    <div className="container">
      <div
        className="footer-top-1 bg-theme"
        style={{ backgroundImage: "url(assets/img/bg/footer-top-bg1-1.png)" }}
      >
        <div className="footer-logo">
          <Link to="/">
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/logo/able-fellas-logo-color.png`}
              alt="Able Fellas"
            />
          </Link>
        </div>
        <div className="call-media-wrap">
          <div className="icon">
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/icon/phone-1.svg`}
              alt="Able Fellas"
            />
          </div>
          <div className="media-body">
            <h6 className="title text-white"> Need Help Moving? </h6>
            <h4 className="link">
              <a className="text-white" href="tel: 647-767-3037">
                647-767-3037
              </a>
            </h4>
          </div>
        </div>
        <div className="social-btn">
          <a href="https://www.facebook.com/profile.php?id=100071690435904">
            <i className="fab fa-facebook-f" />
          </a>
          <a href="https://www.instagram.com/able6488">
            <i className="fab fa-instagram" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CTA;
