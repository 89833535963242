import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div
      className="hero-wrapper hero-2"
      id="hero"
      style={{
        backgroundImage:
          "url(https://img.freepik.com/free-photo/cargo-delivery-vehicle_1048-17314.jpg?t=st=1715442140~exp=1715445740~hmac=f6d1755e9370077a06a6bf8532c048f3731666ec1a2c111949a00bb6fb135b1a&w=1380)",
      }}
    // style={{
    //   backgroundImage: "url(https://thekatynews.com/wp-content/uploads/2024/04/Truck-unloading.jpg)",
    //   backgroundSize: "cover",
    //   backgroundRepeat: "no-repeat",
    //   height: "100vh",
    //   width: "100%",
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center"
    // }}
    >
      <div className="hero-shape2-1">
        <span className="hero-shape2-2" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xxl-6 col-xl-5 col-lg-8">
            <div className="hero-style2">
              <span className="sub-title text-white">
                {" "}
                Moving You Forward, Faster and Safer{" "}
              </span>
              <h1 className="hero-title text-white">
                Precision{" "}
                <span>
                  <img
                    src="assets/img/hero/hero_shape_2.png"
                    alt="Able Fellas"
                  />
                  Excellence
                </span>{" "}
                and Seamless Moves for Stress-Free Transitions
              </h1>
              <p className="hero-text text-white">
                At Able Fellas, we turn the art of moving into a science. From
                local home relocations to comprehensive business moves, every
                step is handled with unmatched precision and care.
              </p>
              <div className="btn-group">
                <Link to="/about" className="btn">
                  Learn More
                </Link>
                <div className="call-media-wrap">
                  <div className="icon">
                    <img src="assets/img/icon/phone-1.svg" alt="Able Fellas" />
                  </div>
                  <div className="media-body">
                    <h6 className="title text-white"> Need Help Moving? </h6>
                    <h4 className="link">
                      <Link className="text-white" to="tel:  647-767-3037">
                        647-767-3037
                      </Link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
