import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import FooterArea from "../components/FooterArea";
import Breadcrumb from "../components/Breadcrumb";
import AboutArea from "../components/AboutUs";
import ProcessArea from "../components/ProcessArea";
import Subscribe from "../components/CTA";
import Preloader from "../helper/Preloader";

const AboutPage = () => {
  let [active, setActive] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 2000);
  }, []);
  return (
    <>
      {/* Preloader */}
      {active === true && <Preloader />}

      {/* Header */}
      <Header />

      {/* Breadcrumb */}
      <Breadcrumb title={"About Us"} />

      {/* About Area */}
      <AboutArea />

      {/* Process Area  */}
      <ProcessArea />

      {/* Subscribe  */}
      <Subscribe />

      {/* Footer  */}
      <FooterArea />
    </>
  );
};

export default AboutPage;
