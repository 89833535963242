import React, { useEffect, useState } from "react";
import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";
import { Link } from "react-router-dom";
import galleryData from "../data/gallery-data";

const Gallery = () => {
  const isotope = React.useRef(Isotope | null);
  const [active, setActive] = useState(1);

  useEffect(() => {
    const imgLoad = imagesLoaded(document.querySelectorAll(".image-container"));

    imgLoad.on("done", () => {
      isotope.current = new Isotope(".filter-container", {
        itemSelector: ".filter-item",
        layoutMode: "masonry",
        percentPosition: true,
      });

      return () => isotope.current?.destroy();
    });

    return () => imgLoad.off("done");
  }, []);

  const handleFilterKeyChange = (key, index) => {
    setActive(index);
    isotope.current?.arrange({ filter: `${key}` });
  };

  return (
    <div className="portfolio-area-1 space">
      <div className="portfolio-shape-img shape-mockup d-lg-block d-none">
        <img
          className="about1-shape-img-1 spin"
          src="assets/img/normal/about_shape1-2.svg"
          alt="Able-fellas"
        />
        <img
          className="about1-shape-img-2 spin2"
          src="assets/img/normal/about_shape1-1.svg"
          alt="Able-fellas"
        />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="title-area text-center">
              <span className="sub-title">Our Gallery</span>
              <h2 className="sec-title"> Seamless Moves, One Step At a Time </h2>
            </div>
          </div>
          <div className="col-12">
            <div className="portfolio-tab-1 mb-60">
              <div className="filter-menu-active text-center">
                <button
                  className={`${active === 1 && "active"}  btn style-border3`}
                  type="button"
                  onClick={() => handleFilterKeyChange("*", 1)}
                >
                  All Gallery
                </button>
                <button
                  className={`${active === 2 && "active"}  btn style-border3`}
                  type="button"
                  onClick={() => handleFilterKeyChange(".pro1", 2)}
                >
                  Local Home Moving
                </button>
                <button
                  className={`${active === 3 && "active"}  btn style-border3`}
                  type="button"
                  onClick={() => handleFilterKeyChange(".pro2", 3)}
                >
                  Business Moving
                </button>
                <button
                  className={`${active === 4 && "active"}  btn style-border3`}
                  type="button"
                  onClick={() => handleFilterKeyChange(".pro3", 4)}
                >
                  Packing Services
                </button>
                <button
                  className={`${active === 5 && "active"}  btn style-border3`}
                  type="button"
                  onClick={() => handleFilterKeyChange(".pro4", 5)}
                >
                  Moving Boxes
                </button>
                <button
                  className={`${active === 6 && "active"}  btn style-border3`}
                  type="button"
                  onClick={() => handleFilterKeyChange(".pro5", 6)}
                >
                  Long Distance
                </button>
                <button
                  className={`${active === 7 && "active"}  btn style-border3`}
                  type="button"
                  onClick={() => handleFilterKeyChange(".pro6", 7)}
                >
                  Restaurant Equipment
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row gy-30 gx-30 filter-container">
          {galleryData?.map((gallery, index) => (
            <div
              key={index}
              className={`col-lg-4 filter-item ${gallery?.category}`}
            >
              <div className="portfolio-card image-container">
                <div className="portfolio-card-thumb">
                  <img src={gallery?.imageUrl} alt="Able-fellas" />
                </div>
                <div className="portfolio-card-details">
                  <div className="media-left">
                    {/* <span className="portfolio-card-details_subtitle">
                      Our Projects
                    </span> */}
                    <h4 className="portfolio-card-details_title">
                      <Link to={`/service-details/${gallery?.categoryId}`}>{gallery?.title}</Link>
                    </h4>
                  </div>
                  <Link to={`/service-details/${gallery?.categoryId}`} className="icon-btn">
                    <i className="fas fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
