import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import servicesData from "../data/services-data";

const ServiceDetails = () => {
  const { id } = useParams();

  const service = servicesData.find((service) => service.id === parseInt(id));

  return (
    <div className="service-details-area space-top space-extra-bottom overflow-hidden">
      <div className="container">
        <div className="row gx-40 flex-row-reverse">
          <div className="col-xxl-8 col-lg-7">
            <div className="service-page-single">
              <div className="page-img mb-45">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/service/${service?.imageDetails}`}
                  alt="Able-fellas"
                />
              </div>
              <div className="page-content">
                <h2 className="page-title">{service?.titleDetails}</h2>
                <p className="mb-25">{service?.description}</p>
                <h3 className="page-subtitle mb-15">{service?.subtitle}</h3>
                <p className="mb-30">{service?.details[0]}</p>
                <div className="checklist style2 mt-35 mb-20">
                  <ul>
                    {service?.details?.slice(1).map((detail) => (
                      <li key={detail.text}>
                        <i className={detail.icon} /> {detail.text}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-4 col-lg-5">
            <aside className="sidebar-area">
              <div className="widget widget_contact">
                <div className="widget-contact-wrap text-center">
                  <h3 className="widget_title">Contact Us</h3>
                  <p className="widget-contact-text">
                    Whether you’re moving locally in GTA or need special moving
                    services — we're here to help.
                  </p>
                  <div className="icon">
                    <i className="fas fa-phone-alt" />
                  </div>
                  <p className="widget-contact-text">Need Help Moving?</p>
                  <h5>
                    <Link to="tel: 647-767-3037"> 647-767-3037</Link>
                  </h5>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
