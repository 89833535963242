import React from "react";

const FeatureArea = () => {
  return (
    <div className="feature-area-1">
      <div className="container">
        <div className="row gy-4 justify-content-center">
          <div className="col-xl-4">
            <div className="single-feature-wrap">
              <div className="thumb">
                <img src="assets/img/features/able-fellas-features-image-1.png" alt="able-fellas-features" />
              </div>
              <div className="media-body">
                <h4>Proven Expertise</h4>
                <p>Over 15 years delivering expert, reliable service.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="single-feature-wrap">
              <div className="thumb">
                <img src="assets/img/features/able-fellas-features-image.png" alt="able-fellas-features" />
              </div>
              <div className="media-body">
                <h4>Total Satisfaction</h4>
                <p>Complete satisfaction for every client, guaranteed.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="single-feature-wrap">
              <div className="thumb">
                <img src="assets/img/features/able-fellas-features-image-2.png" alt="able-fellas-features" />
              </div>
              <div className="media-body">
                <h4>Fair Pricing</h4>
                <p>Clear pricing, no hidden fees, and true affordability.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureArea;
