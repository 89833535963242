const galleryData = [
  {
    imageUrl: "assets/img/fella/image1.png",
    category: "pro1",
    title: "Local Home Moving",
    categoryId: 1,
  },
  {
    imageUrl: "assets/img/fella/image2.png",
    category: "pro3",
    title: "Packing Services",
    categoryId: 3,
  },
  {
    imageUrl: "assets/img/fella/image3.png",
    category: "pro4",
    title: "Moving Boxes and Supplies",
    categoryId: 4,
  },
  {
    imageUrl: "assets/img/fella/image4.png",
    category: "pro5",
    title: "Long Distance Moving",
    categoryId: 5,
  },
  {
    imageUrl: "assets/img/fella/image5.png",
    category: "pro2 pro5",
    title: "Business Moving",
    categoryId: 2,
  },
  {
    imageUrl: "assets/img/fella/image7.png",
    category: "pro2 pro5",
    title: "Business Moving",
    categoryId: 2,
  },
  {
    imageUrl: "assets/img/fella/image8.png",
    category: "pro5 pro4",
    title: "Long Distance",
    categoryId: 1,
  },
  {
    imageUrl: "assets/img/fella/image9.png",
    category: "pro1",
    title: "Local Home Moving",
    categoryId: 1,
  },
  {
    imageUrl: "assets/img/fella/image10.png",
    category: "pro6 pro5",
    title: "Restaurant Equipment & Bakary Moving",
    categoryId: 6,
  },
  {
    imageUrl: "assets/img/fella/image13.png",
    category: "pro5 pro2",
    title: "Long Distance",
    categoryId: 5,
  },
  {
    imageUrl: "assets/img/fella/image14.png",
    category: "pro6 pro5",
    title: "Restaurant Equipment & Bakary Moving",
    categoryId: 6,
  },
  {
    imageUrl: "assets/img/fella/image15.png",
    category: "pro1",
    title: "Local Home Moving",
    categoryId: 1,
  },
  {
    imageUrl: "assets/img/fella/image16.png",
    category: "pro2",
    title: "Business Moving",
    categoryId: 2,
  },
  {
    imageUrl: "assets/img/fella/image18.png",
    category: "pro5",
    title: "Long Distance",
    categoryId: 5,
  },
  {
    imageUrl: "assets/img/fella/image19.png",
    category: "pro1",
    title: "Local Home Moving",
    categoryId: 1,
  },
  {
    imageUrl: "assets/img/fella/image20.png",
    category: "pro2",
    title: "Business Moving",
    categoryId: 2,
  },
  {
    imageUrl: "assets/img/fella/image21.png",
    category: "pro2",
    title: "Business Moving",
    categoryId: 2,
  },
  {
    imageUrl: "assets/img/fella/image22.png",
    category: "pro2",
    title: "Business Moving",
    categoryId: 2,
  },
  {
    imageUrl: "assets/img/fella/image23.png",
    category: "pro6",
    title: "Restaurant Equipment & Bakary Moving",
    categoryId: 1,
  }
];
export default galleryData;
