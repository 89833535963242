import React from 'react';
import { Link } from 'react-router-dom';
import processSteps from '../data/moving-process';

const MovingProcess = () => {
  return (
    <div
      className="process-area-2 space-top"
      style={{ backgroundImage: "url(/assets/img/bg/bg-moving-process.jpg)" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-7">
            <div className="title-area text-center">
              <h3 className="sub-title">Moving Process</h3>
              <h2 className="sec-title text-white">
                Seamless Moves, One Step At A Time
                <img
                  className="title-bg-shape shape-center"
                  src="assets/img/bg/title-bg-shape2.png"
                  alt="Able Fellas"
                />
              </h2>
            </div>
          </div>
        </div>
        <div className="row gy-4 gx-90 justify-content-center">
          {processSteps.map((step) => (
            <div key={step.id} className="col-lg-4 col-md-6 process-card-wrap2">
              <div className="process-card style2">
                <div className="process-card-number">STEP {step.id}</div>
                <h4 className="process-card-title">{step.title}</h4>
                <p className="process-card-text">{step.text}</p>
                <Link to={step.link} className="link-btn style2">
                  Read More <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MovingProcess;
