import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import FooterArea from "../components/FooterArea";
import Breadcrumb from "../components/Breadcrumb";
import SubscribeOne from "../components/CTA";
import ServiceDetails from "../components/ServiceDetails";
import Preloader from "../helper/Preloader";

const ServiceDetailsPage = () => {
  let [active, setActive] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 2000);
  }, []);
  return (
    <>
      {/* Preloader */}
      {active === true && <Preloader />}

      {/* Header  */}
      <Header />

      {/* Breadcrumb */}
      <Breadcrumb title={"Service Details"} />

      {/* Service Details */}
      <ServiceDetails />

      {/* Subscribe  */}
      <SubscribeOne />

      {/* Footer Area  */}
      <FooterArea />
    </>
  );
};

export default ServiceDetailsPage;
