import React from "react";
import { Link } from "react-router-dom";
import servicesData from "../data/services-data";

const ServiceArea = () => {
  return (
    <div className="service-area-1 space-top bg-smoke overflow-hidden">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="title-area text-center">
              <span className="sub-title">Our Services</span>
              <h2 className="sec-title">
                Trusted Movers – The Professionals Who Handle With Care
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gy-4 justify-content-center">
          {servicesData.map((service) => (
            <div key={service.id} className="col-lg-4 col-md-6">
              <div className="service-card">
                <div className="service-card_img pl-40 pr-40">
                  <img
                    src={`assets/img/service/${service.img}`}
                    alt={service.title}
                  />
                </div>

                <div className="service-card_content mt-30">
                  <h4 className="service-card_title h5">
                    <Link to={`service-details/${service.id}`}>
                      {service.title}
                    </Link>
                  </h4>
                  <p className="service-card_text">{service.text}</p>
                  <Link
                    to={`service-details/${service.id}`}
                    className="link-btn"
                  >
                    Read More <i className="fas fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="btn-wrap mt-40 mb-40 flex justify-content-center">
          <Link to="/gallery" className="btn style2">
            View Gallery <i className="fas fa-arrow-right ms-2" />
          </Link>
        </div>
      </div>


    </div>
  );
};

export default ServiceArea;
