const servicesData = [
  {
    id: 1,
    icon: "service-icon_1-1.svg",
    title: "Local Home Moving",
    text: "Ensuring your local move is smooth and stress-free, we prioritize your satisfaction by providing efficient and careful handling of all your home belongings.",
    img: "able-fellas-moving-boxes.jpg",

    imageDetails: "able-fellas-moving-boxes.jpg",
    titleDetails: "Simplify Your Local Move with Able Fellas",
    description:
      "Moving within the Greater Toronto Area doesn't need to be complicated. With Able Fellas, you get more than just a moving service; you get over 15 years of dedicated experience ensuring your move is smooth and stress-free.",
    subtitle: "Why Choose Able Fellas?",
    details: [
      "Trusted for over a decade, our team not only moves your belongings but cares for them as if they were our own. Here's why our clients choose us:",
      {
        icon: "fas fa-angle-double-right",
        text: "Expertise: More than 15 years moving families and businesses locally.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Local Knowledge: Based right here in GTA, saving you time and money.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Top-notch Equipment: We own a versatile fleet including trucks and specialized equipment.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Specialized Services: From heavy lifting to delicate items, we handle it all.",
      },
    ],
  },
  {
    id: 2,
    icon: "service-icon_1-2.svg",
    title: "Business Moving",
    text: "We specialize in relocating businesses efficiently, minimizing downtime and ensuring that every piece of office equipment and furniture is meticulously handled and set up.",
    // img: "able-fellas-service-2.svg",
    img: "able-fellas-moving-business.jpeg",

    imageDetails: "able-fellas-moving-business.jpeg",
    titleDetails: "Efficient Business Moving Solutions",
    description:
      "Relocating a business requires precision and efficiency to minimize downtime. At Able Fellas, we understand the importance of a swift transition, whether it's moving a small office or an entire corporation.",
    subtitle: "Why Trust Able Fellas with Your Business Move?",
    details: [
      "Our approach to business moving is designed to address all your needs effectively. Here’s why businesses trust us for their relocation:",
      {
        icon: "fas fa-angle-double-right",
        text: "Minimized Downtime: We strategize every move to ensure quick and efficient transitions, minimizing disruptions to your operations.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Comprehensive Services: From internal office adjustments to relocating across cities, our services cover every aspect of your business move.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Experienced Team: Our movers are trained professionals who handle your assets with the utmost care and precision.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Customized Planning: We work closely with your team to tailor the moving plan to your specific needs, ensuring a seamless move.",
      },
    ],
  },
  {
    id: 3,
    icon: "service-icon_1-3.svg",
    title: "Packing Services",
    text: "Our professional packing services ensure that every item, from fragile to bulky, is securely packed with the utmost care for a safe and secure transit.",
    // img: "able-fellas-service-3.svg",
    img: "able-fellas-packing-service.jpeg",
    imageDetails: "able-fellas-packing-service.jpeg",
    titleDetails: "Expert Packing, Stress-Free Moving",
    description:
      "Every successful move begins with expert packing. At Able Fellas, we use top-quality packing materials and proven techniques to ensure your items are well-protected throughout the moving process.",
    subtitle: "Customized Packing Solutions",
    details: [
      "Choose Able Fellas for professional assistance with packing. Whether you need help with a single room or an entire home, our trained professionals are ready to assist:",
      {
        icon: "fas fa-angle-double-right",
        text: "Full-Service Packing: Let our trained packers handle everything, from small items to large furniture, ensuring everything is ready for the move.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Personalized Packing Plans: We tailor our packing services to fit your specific needs, whether you're moving home or office.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Packing Supplies: If you prefer to pack yourself, we provide high-quality supplies like boxes, tape, and protective wrap at competitive prices.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Flexible Packing Help: Need packing help but not moving? Whether it’s for storage or pre-renovation, we’re here to help.",
      },
    ],
  },
  {
    id: 4,
    icon: "service-icon_1-3.svg",
    title: "Moving Boxes and Supplies",
    text: "Offer high-quality moving boxes and supplies tailored to protect your belongings, providing everything needed for a well-organized and secure move.",
    // img: "able-fellas-service-4.svg",
    img: "able-fellas-moving-boxes-and-supplies.jpeg",
    imageDetails: "able-fellas-moving-boxes-and-supplies.jpeg",
    titleDetails: "Moving Boxes and Supplies",
    description:
      "Choose the right moving boxes and packing supplies from Able Fellas to ensure every item is safely packed for your move. Our variety ensures your items, from the heaviest to the most delicate, are well-protected.",
    subtitle: "Customized Packing Options",
    details: [
      "Our selection includes everything from small to large boxes, specialized inserts, and protective wrapping materials:",
      {
        icon: "fas fa-angle-double-right",
        text: "Book Box (1.5 cu ft): Perfect for heavy, small items like books and tools.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Medium Box (3 cu ft): Ideal for moderately heavy items like lamps or electronics.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Large Box (4.5 cu ft): Best for bulky, lighter objects such as pillows and linens.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Extra Large Box (6 cu ft): Suitable for lightweight, voluminous items like comforters.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Specialty Boxes: Includes wardrobe boxes for clothes and dish pack boxes for kitchenware, ensuring your items remain organized and secure.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Packing Supplies: From bubble wrap and packing tape to anti-static packing materials for electronics, we've got all the essentials to protect your belongings.",
      },
    ],
  },
  {
    id: 5,
    icon: "service-icon_1-3.svg",
    title: "Long Distance Moving",
    text: "Expertly managing long-distance moves, we ensure a seamless transition to your new home or office, no matter the distance, with continuous support throughout the journey.",
    // img: "able-fellas-service-5.svg",
    img: "able-fellas-long-distance-moving.jpeg",
    imageDetails: "able-fellas-long-distance-moving.jpeg",
    titleDetails: "Move Long Distance With Ease",
    description:
      "Long-distance moving can be a big task, but with Able Fellas, it's stress-free. We leverage over 15 years of moving expertise to ensure your move is handled with the utmost care and efficiency.",
    subtitle: "Why Opt for Able Fellas?",
    details: [
      "Our long-distance moving services are designed to handle every aspect of your move, making the process as smooth as possible. Here’s why you should choose us:",
      {
        icon: "fas fa-angle-double-right",
        text: "Exclusive Moving Services: We ensure fast and secure delivery. Choose your truck size and enjoy next-day delivery within a 1000 km radius.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Shared Container Options: Ideal for smaller moves or single items, share a container and save.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Customized Solutions: From door-to-door services tailored to your needs, to careful handling and storage solutions.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Trusted Experience: With 15+ years serving the GTA and beyond, we're a top choice for hassle-free long-distance moves.",
      },
    ],
  },
  {
    id: 6,
    icon: "service-icon_1-3.svg",
    title: "Restaurant Equipment & Bakary Moving",
    text: "Our team is skilled in moving restaurant equipment efficiently. We understand the intricacies of sensitive items and provide tailored solutions for a hassle-free setup at your new location.",
    // img: "able-fellas-service-6.svg",
    img: "able-fellas-restaurant-equipment-and-bakary-moving.jpeg",
    imageDetails: "able-fellas-restaurant-equipment-and-bakary-moving.jpeg",
    titleDetails: "Expert Restaurant and Bakery Relocations",
    description:
      "Moving a restaurant or bakery involves unique challenges, from handling high-value kitchen equipment to managing delicate baking instruments. At Able Fellas, we're equipped to handle the complexities of commercial kitchen moves, ensuring minimal downtime and a smooth re-setup at your new location.",
    subtitle: "Why Choose Able Fellas for Your Kitchen Move?",
    details: [
      "Our expert movers are trained to address the specific needs of restaurant and bakery equipment relocations:",
      {
        icon: "fas fa-angle-double-right",
        text: "Precision Handling: We utilize specialized equipment and techniques to move your kitchen appliances and bakery tools safely.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Custom Crating: Fragile items are secured in custom-built crates, providing extra protection during transport.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Systematic Disassembly and Reassembly: Our team expertly disassembles and reassembles your equipment to ensure functional integrity post-move.",
      },
      {
        icon: "fas fa-angle-double-right",
        text: "Tailored Logistics: We plan every aspect of the move, from the optimal route to the timing, to minimize operational disruptions.",
      },
    ],
  },
];

export default servicesData;
