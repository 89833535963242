import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
const AboutUs = () => {
  return (
    <div className="space-top">
      <div className="container">
        <div className="row">
          <div className="col-xxl-7 col-xl-6">
            <div className="about-thumb2 mb-40 mb-xl-0">
              <div className="about-img-1">
                <img src="assets/img/about/able-fellas-about-image-1.png" alt="Able-Fellas" />
              </div>
              <div className="about-img-2">
                <img src="assets/img/about/able-fellas-about-image-2.png" alt="Able-Fellas" />
              </div>
              <div className="about-counter-wrap jump-reverse">
                <img src="assets/img/icon/about_icon2-1.svg" alt="Able-Fellas" />
                <h3 className="about-counter">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter-number">
                          <CountUp delay={0} start={0} end={100} />
                          %
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h3>
                <h4 className="about-counter-text">Client Satisfaction</h4>
              </div>
              <div className="about-year-wrap2 movingX">
                <div className="about-year-grid-wrap">
                  <div className="icon">
                    <img src="assets/img/icon/about_icon2-2.png" alt="Fixturbo" />
                  </div>
                  <h3 className="about-counter">
                    <span className="counter-number">15</span>+
                  </h3>
                </div>
                <h4 className="about-year-text">Years Of Experiences</h4>
              </div>
            </div>
          </div>
          <div className="col-xxl-5 col-xl-6">
            <div className="about-content-wrap">
              <div className="title-area mb-30">
                <span className="sub-title">About Us</span>
                <h2 className="sec-title">
                  Our Story
                  <img
                    className="title-bg-shape shape-center"
                    src="assets/img/bg/title-bg-shape.png"
                    alt="Fixturbo"
                  />
                </h2>
                <p className="sec-text">
                  Able Fellas has been a consistent and trustworthy Moving Company in and around the GTA, offering a comprehensive range of services including commercial and residential moving, as well as specialized restaurant and office equipment relocations. Our team is dedicated to ensuring efficiency and quality in every move, focused on making the transition seamless and stress-free for all our clients.
                </p>
              </div>
              <div className="about-feature-wrap style-shadow">
                <div className="icon">
                  <img src="assets/img/about/able-fellas-mission.png" alt="Able-fellas" />
                </div>
                <div className="about-feature-wrap-details">
                  <h5 className="about-feature-title">
                    Our Mission
                  </h5>
                  <p className="about-feature-text">
                    At Able Fellas, our mission is to make every move, whether big or small, as smooth and stress-free as possible. We take pride in handling your belongings with the care they deserve.
                  </p>
                </div>
              </div>
              <div className="about-feature-wrap style-shadow">
                <div className="icon">
                  <img src="assets/img/about/able-fellas-values.png" alt="Able-fellas" />
                </div>
                <div className="about-feature-wrap-details">
                  <h5 className="about-feature-title">Our Value</h5>
                  <p className="about-feature-text">
                    Your satisfaction drives us. It’s why we tailor every service to fit your needs, ensuring that your experience is seamless from start to finish.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default AboutUs;
