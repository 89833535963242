import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import ProcessAreaTwo from "../components/ProcessAreaTwo";
import FaqArea from "../components/FaqArea";
import FooterArea from "../components/FooterArea";
import Preloader from "../helper/Preloader";
import ServiceArea from "../components/ServiceArea";
import FeatureArea from "../components/FeatureArea";
import AboutArea from "../components/AboutArea";
import SubscribeOne from "../components/CTA";
// import MarqueeArea from "../components/MarqueeArea";

const Home = () => {
  let [active, setActive] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 2000);
  }, []);
  return (
    <>
      {/* Preloader */}
      {active === true && <Preloader />}

      {/* Header */}
      <Header />

      {/* Hero  */}
      <Hero />

      {/* Feature Area  */}
      <FeatureArea />

      {/* About  */}
      <AboutArea />

      {/* Service Area  */}
      <ServiceArea />

      {/* Company */}
      {/* <MarqueeArea /> */}

      {/* Process Area */}
      <ProcessAreaTwo />

      {/* Faq Area  */}
      <FaqArea />

      {/* Subscribe  */}
      <SubscribeOne />

      {/* Footer Area  */}
      <FooterArea />
    </>
  );
};

export default Home;
