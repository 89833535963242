import React from "react";

const ProcessAreaOne = () => {
  return (
    <section className="process-area-1 space position-relative">
      <div className="portfolio-shape-img shape-mockup d-lg-block d-none">
        <img
          className="about1-shape-img-1 spin"
          src="/assets/img/normal/about_shape1-2.svg"
          alt="Fixturbo"
        />
        <img
          className="about1-shape-img-2 spin2"
          src="/assets/img/normal/about_shape1-1.svg"
          alt="Fixturbo"
        />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="title-area text-center">
              <span className="sub-title"> MOVING PROCESS </span>
              <h2 className="sec-title">
                Seamless Moves, One Step At a Time
              </h2>
            </div>
          </div>
        </div>
        <div className="row gy-30">
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card">
              <div className="process-card-icon">
                <h2 className="text-white pt-mobile">1</h2>
              </div>
              <h4 className="process-card-title">Consultation & Planning</h4>
              <p className="process-card-text">
                We start by understanding your moving needs and timeline to plan every detail meticulously. Our expert team ensures a personalized and efficient moving strategy.
              </p>
            </div>
          </div>
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card process-card-center">
              <div className="process-card-icon">
                <h2 className="text-white pt-mobile">2</h2>
              </div>
              <h4 className="process-card-title">Packing & Handling</h4>
              <p className="process-card-text">
                Our skilled packers use the best materials and techniques to protect your belongings. Every item, from the most robust to the most delicate, is handled with care.
              </p>
            </div>
          </div>
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card">
              <div className="process-card-icon">
                <h2 className="text-white pt-mobile">3</h2>
              </div>
              <h4 className="process-card-title">Delivery & Setup</h4>
              <p className="process-card-text">
                We ensure timely delivery to your new location and can assist with the setup of furniture and appliances to help you settle into your new home or office faster.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcessAreaOne;
