import React from "react";
import { Link } from "react-router-dom";

const AboutArea = () => {
  return (
    <div className="about-area-1 space">
      <div className="about1-shape-img shape-mockup">
        <img
          className="about1-shape-img-1 spin"
          src="assets/img/normal/about_shape1-2.svg"
          alt="Able-fellas-about"
        />
        <img
          className="about1-shape-img-2 spin2"
          src="assets/img/normal/about_shape1-1.svg"
          alt="Able-fellas-about"
        />
      </div>
      <div className="container">
        <div className="row gx-60 align-items-center flex-row-reverse">
          <div className="col-xl-7 text-xl-center">
            <div className="about-thumb3 mb-40 mb-xl-0">
              <div className="about-img-1">
                <img src="assets/img/about/able-fellas-about-moving.jpg" alt="Able-fellas-about" />
              </div>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="about-content-wrap">
              <div className="title-area me-xl-5 mb-30">
                <span className="sub-title"> About Us</span>
                <h2 className="sec-title">Our Story</h2>
                <p className="sec-text">
                  Able Fellas has been a consistent and trustworthy Moving Company in and around the GTA, offering a comprehensive range of services including commercial and residential moving, as well as specialized restaurant and office equipment relocations. Our team is dedicated to ensuring efficiency and quality in every move, focused on making the transition seamless and stress-free for all our clients.
                </p>
              </div>
              <div className="row gy-4 justify-content-md-between justify-content-end align-items-center flex-row-reverse">
                <div className="col-md-12">
                  <div className="checklist style2">
                    <ul>
                      <li>
                        <i className="fas fa-check-double" />
                        Personalized Moving Plans
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Expert Handling of Belongings
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Comprehensive Insurance Coverage
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Prompt and Reliable Service
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Safe and Secure Storage Options
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="btn-wrap mt-40">
                <Link to="/about" className="btn style2">
                  Read More <i className="fas fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutArea;
