import React, { useEffect, useState } from "react";
import Header from "../components/Header";

import FooterArea from "../components/FooterArea";
import Breadcrumb from "../components/Breadcrumb";
import SubscribeOne from "../components/CTA";
import ContactArea from "../components/ContactArea";
import Preloader from "../helper/Preloader";

const ContactPage = () => {
  let [active, setActive] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 2000);
  }, []);
  return (
    <>
      {/* Preloader */}
      {active === true && <Preloader />}

      {/* Header  */}
      <Header />

      {/* Breadcrumb */}
      <Breadcrumb title={"Contact"} />

      {/* Contact Area */}
      <ContactArea />

      {/* Subscribe One */}
      <SubscribeOne />

      {/* Footer Area One */}
      <FooterArea />
    </>
  );
};

export default ContactPage;
